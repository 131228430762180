import margherita from '../assets/img/margherita.jpg'
import quattro_Fromagio from '../assets/img/4-fromages.jpg'
import bolognaise from '../assets/img/bolognaise.jpg'
import chorizo from '../assets/img/chorizo.jpg'
import cipolla from '../assets/img/cipolla.jpg'
import napoli from '../assets/img/napoli.jpg'
import hawai from '../assets/img/hawai.jpg'
import barbecue from '../assets/img/barbecue.jpg'
import tartiflette from '../assets/img/tartiflette.jpg'
import calzone from '../assets/img/calzone.jpg'

// Liste des pizzas disponibles

export const pizzaList = [
	{
		name: 'margherita',
		taille: 'classique',
		id: '1a',
		ingredient: 'tomate, mozzarella, basilic',
		photo: margherita,
		price: 10

	},
	{
		name: 'margherita-L',
		taille: 'large',
		id: '1b',
		ingredient: 'tomate, mozzarella, basilic',
		photo: margherita,
		price: 13
	},

	{
		name: '4fromages',
		taille: 'classique',
		id: '10a',
		ingredient: 'tomate, mozzarella, cheddar, comté, gorgonzola',
		photo: quattro_Fromagio,
		price: 10.50
	},
	{
		name: '4fromages-L',
		taille: 'large',
		id: '10b',
		ingredient: 'tomate, mozzarella, cheddar, comté, gorgonzola',
		photo: quattro_Fromagio,
		price: 13.50
	},

	{
		name: 'bolognaise',
		taille: 'classique',
		id: '2a',
		ingredient: 'tomate, haché de boeuf, mozza',
		photo: bolognaise,
		price: 11.50
	},
	{
		name: 'bolognaise-L',
		taille: 'large',
		id: '2b',
		ingredient: 'tomate, haché de boeuf, mozza',
		photo: bolognaise,
		price: 13.50
	},

	{
		name: 'chorizo',
		taille: 'classique',
		ingredient: 'tomate, mozzarella, salami piquant',
		id: '3a',
		photo: chorizo,
		price: 11
	},
	{
		name: 'chorizo-L',
		taille: 'large',
		ingredient: 'tomate, mozzarella, salami piquant',
		id: '3b',
		photo: chorizo,
		price: 13.50
	},
	{
		name: 'cipolla',
		taille: 'classique',
		ingredient: 'tomate, mozzarella, oignons',
		id: '4a',
		photo: cipolla,
		price: 10.50
	},
	{
		name: 'cipolla-L',
		taille: 'large',
		ingredient: 'tomate, mozzarella, oignons',
		id: '4b',
		photo: cipolla,
		price: 12.50
	},
	{
		name: 'napoli',
		taille: 'classique',
		ingredient: 'tomate, mozzarella, anchois, câpres',
		id: '5a',
		photo: napoli,
		price: 11
	},
	{
		name: 'napoli-L',
		taille: 'large',
		ingredient: 'tomate, mozzarella, anchois, câpres',
		id: '5b',
		photo: napoli,
		price: 13
	},

	{
		name: 'hawai',
		taille: 'classique',
		ingredient: 'tomate, mozzarella, jambon, ananas',
		id: '6a',
		photo: hawai,
		price: 11.50
	},
	{
		name: 'hawai-L',
		taille: 'large',
		ingredient: 'tomate, mozzarella, jambon, ananas',
		id: '6b',
		photo: hawai,
		price: 13.50
	},
	{
		name: 'barbecue',
		taille: 'classique',
		ingredient: 'tomate, mozzarella, oignons, lardons, sauce barbecue',
		id: '7a',
		photo: barbecue,
		price: 12
	},
	{
		name: 'barbecue-L',
		taille: 'large',
		ingredient: 'tomate, mozzarella, oignons, lardons, sauce barbecue',
		id: '7b',
		photo: barbecue,
		price: 15
	},
	{
		name: 'tartiflette',
		taille: 'classique',
		ingredient: 'reblochon,pommes de terre, lardons, oignons',
		id: '8a',
		photo: tartiflette,
		price: 13
	},
	{
		name: 'tartiflette-L',
		taille: 'large',
		ingredient: 'tomate, pommes de terre, lardons, oignons',
		id: '8b',
		photo: tartiflette,
		price: 16
	},

	{
		name: 'calzone',
		taille: 'classique',
		ingredient: 'tomate, mozzarella, jambon, champignons',
		id: '9a',
		photo: calzone,
		price: 12
	},

	{
		name: 'calzone-L',
		taille: 'large',
		ingredient: 'tomate, mozzarella, jambon, champignons',
		id: '9b',
		photo: calzone,
		price: 15
	}
]


export const supplementList = [
	{

		id: '1',
		supplement: 'fromage',
		price: 1.2

	},
	{

		id: '2',
		supplement: 'mozzarela',
		price: 1.2

	},
	{

		id: '3',
		supplement: 'jambon',
		price: 1.5

	},
	{

		id: '4',
		supplementt: 'olive',
		price: 1

	},
	{

		id: '5',
		supplement: 'champignon',
		price: 1

	},
	{

		id: '6',
		supplement: 'mergez',
		price: 2

	}
]