/* useState et useEffect => importation de fonctionnalités react*/
import { useState, useEffect } from "react";

/* importation du style, image, etc.*/
import "../styles/app.css";
import logo from "../assets/img/logo-meraviglioso.svg";

/* importation des components utilisés*/
import ListPizzas from "./ListPizzas";
import { Header } from "./Header";
import Panier from "./Panier";
import { Footer } from "./Footer"


function App() {
  // savedPanier: endroit où sont sauvegardées les données pour garder en mémoire le panier complet
  const savedPanier = localStorage.getItem("panier");
  // gestion du panier 
  const [panier, updatePanier] = useState(
    savedPanier ? JSON.parse(savedPanier) : []
  );
  // sauvegarde automatique du panier quand on le modifie  
  useEffect(() => {
    localStorage.setItem("panier", JSON.stringify(panier));
  }, [panier]);

  /*
  le component App est structurée avec une Navbar au dessus,
  un panier à gauche,
  la liste des pizzas à droite. (définis dans le css)
  */
  return (
    <div>
      <Header>
        <img src={logo} alt="meraviglioso" className="mera-logo" />
      </Header>
      <div className="englobe-panier-listpizzas">
        <div className="panier">
          <Panier panier={panier} updatePanier={updatePanier} />
        </div>
        <div className="list-pizzas">
          <ListPizzas panier={panier} updatePanier={updatePanier} />
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
