import React from "react";
import { useState } from "react";
import { pizzaList } from "../datas/pizzaList";
import { Pizza } from "./Pizza";
import { TypePizza } from "./TypePizza";
import "../styles/list-pizzas.css";

function ListPizzas({ panier, updatePanier }) {
  // type de la pizza : activeType
  const [activeType, setActiveType] = useState("classique");
  // liste de tailles de pizzas possibles à mettre dans le sélecteur typePizza(select)
  const tailles = pizzaList.reduce(
    (acc, elem) => (acc.includes(elem.taille) ? acc : acc.concat(elem.taille)),
    []
  );

  // permet d'ajouter au panier une pizza
  function addToPanier(name, price, code, suppl) {
    // recherche de la pizza si elle existe dans le panier
    const currentPizzaInCart = panier.find(
      (pizza) => pizza.name === name && pizza.code === code
    );
    // Si la pizza existe déjà dans le panier,
    if (currentPizzaInCart) {
      const panierFilteredCurrentPizza = panier.filter(
        (pizza) =>
          pizza.name !== name || (pizza.name === name && pizza.code !== code)
      );
      //  alors on ajoute 1 à la qty,
      updatePanier([
        ...panierFilteredCurrentPizza,
        {
          name,
          price,
          code,
          suppl,
          qty: currentPizzaInCart.qty + 1,
        },
      ]);
      // sinon on crée une nouvelle pizza avec la qty = 1
    } else {
      updatePanier([...panier, { name, price, code, suppl, qty: 1 }]);
    }
  }

  return (
    <div className="container-pizza-liste">
      <TypePizza
        tailles={tailles}
        setActiveType={setActiveType}
        activeType={activeType}
      />

      <ul className="pizza-liste">
        {/* liste triée en fonction du select */}
        {pizzaList.map(
          ({ id, photo, name, price, taille, ingredient, supplement }) =>
            !activeType || activeType === taille ? (
              <div className="carte-pizza" key={id}>
                <Pizza
                  photo={photo}
                  name={name}
                  price={price}
                  taille={taille}
                  ingredient={ingredient}
                  supplement={supplement}
                  panier={panier}
                  addToPanier={addToPanier}
                />
              </div>
            ) : null
        )}
      </ul>
    </div>
  );
}

export default ListPizzas;
