import React, { useState } from "react";
import ReactDOM from "react-dom";
import CheckBoxList from "./CheckBoxList";
import "../styles/modal.css";


export const Modal = ({ hide, pizza, addToPanier }) => {
  // coder les détails d'une pizza avec l'index des garnitures supplémentaires possibles 
  // exemple : chorizo='a', paprika='b', etc. => code = 'ab'
  const [code, setCode] = useState("");
  // coder le total des suppléments
  const [suppl, setSuppl] = useState(0);
  let totalPrice;
  // gestion de la fenêtre modale
  return ReactDOM.createPortal(
    <>
      <div className="cde-fond-ecran">
        <div className="cde-content">
          <div>
            {/* permet de fermer la modale */}
            <button className="btn bleu" onClick={() => hide(false)}>
              X
            </button>
          </div>
          <div className="cde-title">{pizza.name}</div>
          <div className="cde-price">{pizza.price} €</div>
          <div className="cde-ingredient">
            Composition: <br /> <br />
            {pizza.ingredient}
          </div>
          <div className="cde-body">
            {
              <CheckBoxList
                setCode={setCode}
                code={code}
                setSuppl={setSuppl}
                suppl={suppl}
                totalPrice={totalPrice}
              />
            }
          </div>

          <div className="cde-footer">
            <div>
              {/* ajoute la pizza et éventuellement les details dans le panier
               si ils sont cochés et referme la modale avec hide(false) */}
              <button
                className="btn vert"
                onClick={() => {
                  addToPanier(pizza.name, pizza.price, code, suppl);
                  hide(false);
                }}
              >
                Ajouter au panier
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.body
  );
};
export default Modal;
