import "../styles/detail_suppl.css";
import { garniture } from "../datas/garniture";

const getFormattedPrice = (price) => `${price.toFixed(2)} €`;

export const DetailSuppl = ({ code, suppl }) => {
  return garniture.map((line) => {
    return (
      // on vérifie si line.id appartient à code et on affiche la ligne complète du supplément (nom + prix) si ça existe.
      code.includes(line.id) && (
        <div className="detail-line" key={line.id}>
          <div className="detail-line-name">{line.name}</div>
          <div className="detail-line-price">
            {getFormattedPrice(line.price)}
          </div>
        </div>
      )
    );
  });
};
