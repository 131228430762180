import React from 'react'
import '../styles/header.css'


//Génère un header avec un logo
export const Header = ({ children }) => {
  return (

    <div className='header'>
      <div className='logo'>{children}</div>
    </div>
  )
}
