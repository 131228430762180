import React, { useState } from "react";
import Modal from "./Modal";
import "../styles/pizza.css";


// Pizza affichera une carte de pizza avec son nom, prix, img et ingrédients. 
// En cliquant sur cette carte on va appeler les détails de suppléments possible (par modal)
export const Pizza = ({
  photo,
  name,
  ingredient,
  supplement,
  price,
  panier,
  addToPanier

}) => {
  // détails de la pizza
  const pizza = { photo, name, ingredient, supplement, price };
  // Etat de la modal (visible ou pas visible)
  // visible => showModal = true
  // pas visible => showModal = false
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="meraviglioso-pizzas">

        <div className="meraviglioso-pizza-item" onClick={() => setShowModal(true)}>
          <div className="pizza-name">
            {name}
            <span className="pizza-price">{price} €</span>
          </div>
          <img
            className="meraviglioso-pizza-photo"
            src={photo}
            alt={`${name}`}

          />
          <p className="pizza-ingredient">Ingrédient: {ingredient}</p>
        </div>
        {/* Si la variable d'état showModal est true alors on affiche la modal sinon elle reste non visible*/}
        {showModal && (
          <Modal
            hide={setShowModal}
            pizza={pizza}
            panier={panier}
            addToPanier={addToPanier}
          >
            <li className="meraviglioso-pizza-item">
              <div className="pizza-name">
                {name}
                <span className="pizza-price">{price} €</span>
              </div>

              <p className="pizza-ingredient">Ingrédient: {ingredient}</p>
              <p className="pizza-supplement">{supplement}</p>
            </li>
          </Modal>
        )}
      </div>

    </>
  );
};
