import "../styles/type-pizza.css";

// TypePizza gère la liste déroulante des tailles de pizzas
export function TypePizza({ setActiveType, tailles, activeType }) {
  return (
    <div className="type-pizza">
      <select
        value={activeType}
        onChange={(e) => setActiveType(e.target.value)}
        className="pizza-tailles-select"
      >
        {tailles.map((taille) => (
          <option key={taille} value={taille}>
            {taille}
          </option>
        ))}
      </select>
    </div>
  );
}
