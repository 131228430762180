// Liste des suppléments de garnitures disponibles

export const garniture = [
  {
    id: 'a',
    name: "Chorizo",
    price: 2.5
  },
  {
    id: 'b',
    name: "Maroille",
    price: 3
  },
  {
    id: 'c',
    name: "Paprika",
    price: 0.5
  },
  {
    id: 'd',
    name: "Oignons",
    price: 2.1
  },
  {
    id: 'e',
    name: "Gorgonzolla",
    price: 2.5
  },
  {
    id: 'f',
    name: "Mozzarella",
    price: 2.2
  },
  {
    id: 'g',
    name: "Champignons",
    price: 2.4
  }
];
