import React, { useState } from "react";
import { garniture } from "../datas/garniture";
import "../styles/check_box_list.css";


const getFormattedPrice = (price) => `${price.toFixed(2)} €`;

/*
CheckBoxList va gérer la liste des cases de toutes les checkBox (checked ou pas checked);
Elle va générer un code vers le panier. Ce code est un string qui est constitué de tous les index 
de l'ID ( égal à une seule lettre) de la garniture ajoutée parmi toute la liste des garnitures.  
Elle va générer aussi le prix total de ces suppléments.
*/

export default function CheckBoxList({ code, setCode, suppl, setSuppl }) {
  // création de la liste de toute les cases à cocher (cochées ou non cochées)
  const [checkedState, setCheckedState] = useState(
    new Array(garniture.length).fill(false)
  );
  // fonction de changement d'état des cases à cocher
  const handleOnChange = (position) => {
    const checkListModif = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    // mise a jour de la liste checkedState
    setCheckedState(checkListModif);

    // calcul du total modifié
    const totalSupplement = checkListModif.reduce((accumulator, currentState, index) => {
      if (currentState === true) {
        return accumulator + garniture[index].price;
      }
      return accumulator;
    }, 0);

    // calcul du code des garnitures supplémentaire (récupéré par le panier)
    const listSuppl = checkListModif.reduce((accumulator, currentState, index) => {
      if (currentState === true) {
        return accumulator + garniture[index].id;
      }
      return accumulator;
    }, "");
    setCode(listSuppl);
    setSuppl(totalSupplement);
  };

  return (
    <div className="check-box-list">
      <h3>Selectionnez la garniture</h3>
      <ul className="garnitures-list">
        {garniture.map(({ name, price }, index) => {
          return (
            <li key={index}>
              <div className="garnitures-list-item">
                <div className="left-section">
                  <input
                    type="checkbox"
                    id={`custom-checkbox-${index}`}
                    name={name}
                    value={name}
                    checked={checkedState[index]}
                    onChange={() => handleOnChange(index)}
                  />
                  <label htmlFor={`custom-checkbox-${index}`}>{name}</label>
                </div>
                <div className="right-section">{getFormattedPrice(price)}</div>
              </div>
            </li>
          );
        })}

        <li>
          <div className="garniture-list-item">
            <div className="section-total">
              Total:
              <span className="pizza-total">{getFormattedPrice(suppl)}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
