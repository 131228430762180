import React from "react";
import { DetailSuppl } from "./DetailSuppl";
import "../styles/line_cart.css";


const getFormattedPrice = (price) => `${price.toFixed(2)} €`;

// détails des lignes de pizzas du panier
export const LineCart = ({ name, index, price, qty, suppl, code }) => {
  return (
    <>
      <div className="panier-line">
        <div className="panier-line-name">{name}</div>
        <div className="panier-line-price">{getFormattedPrice(price)}</div>
        <div className="panier-line-qty">x {qty}</div>
      </div>
      <div>
        {/* détails des lignes de supplément des pizzas du panier */}
        <div>{suppl !== "" && <DetailSuppl code={code} suppl={suppl} />}</div>
      </div>
    </>
  );
};
