import { useEffect } from "react";
import { LineCart } from "./LineCart";
import "../styles/panier.css";

// formatage en deux décimales du prix dans le panier
const getFormattedPrice = (price) => `${price.toFixed(2)} €`;

function Panier({ panier, updatePanier }) {
  // calcul du total du panier  
  const total = panier.reduce(
    (accumulator, pizza) => accumulator + pizza.qty * pizza.price + pizza.suppl,
    0
  );
  // rafraichissement du document.title quand le total change
  useEffect(() => {
    document.title = `Meraviglioso: ${getFormattedPrice(total)} d'achats`;
  }, [total]);

  return (
    <div className="panier">
      <h2>Panier</h2>
      <div>
        {/* liste de toutes les pizzas*/}
        {panier.map(({ name, price, suppl, qty, code }, index) => (
          <div key={index}>
            <LineCart
              name={name}
              price={price}
              suppl={suppl}
              qty={qty}
              index={index}
              code={code}
            />
          </div>
        ))}
      </div>
      <h3 className="panier-total">Total : {getFormattedPrice(total)}</h3>
      {/* remise à zéro du panier*/}
      <button className="panier-btn" onClick={() => updatePanier([])}>
        Vider le panier
      </button>
    </div>
  );
}

export default Panier;
